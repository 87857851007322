import { useState, useEffect, Dispatch, SetStateAction } from "react";

function useSessionStorage<T>(
	key: string,
	initialValue: T
): [T, Dispatch<SetStateAction<T>>] {
	const [storedValue, setStoredValue] = useState<T>(() => {
		try {
			const item = sessionStorage.getItem(key);
			return item ? (JSON.parse(item) as T) : initialValue;
		} catch (error) {
			console.warn(`Error reading sessionStorage key "${key}":`, error);
			return initialValue;
		}
	});

	useEffect(() => {
		try {
			sessionStorage.setItem(key, JSON.stringify(storedValue));
		} catch (error) {
			console.warn(`Error setting sessionStorage key "${key}":`, error);
		}
	}, [key, storedValue]);

	return [storedValue, setStoredValue];
}

export default useSessionStorage;
